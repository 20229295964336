import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';

export default () => (
  <Layout>
    <SEO title="Impressum" keywords={[]} />
    <HeaderSection>
      <h1>
        Impressum
      </h1>
    </HeaderSection>
    <div className="flex flex-col">
      <h3 className="mb-4">Angaben gemäß § 5 TMG:</h3>
      <div className="flex flex-col">
        <p className="font-semibold">Hochzehn GmbH & Co. KG</p>
        <p>Hafenweg 16</p>
        <p>48155 Münster</p>
        <p>Deutschland</p>
        <br />
        <p>
          {'Telefon: '}
          <a href="tel:+4925114982330">+49 (0) 251 149 823 30</a>
        </p>
        <br />
        <p>
          {'E-Mail: '}
          <a href="mailto:hello@hochzehn.com">hello@hochzehn.com</a>
        </p>
        <br />
        <p>Kommanditgesellschaft: Sitz Münster, AG Münster HRA 10257</p>
        <p>Persönlich haftende Gesellschafterin: Hochzehn Verwaltungs GmbH, AG Münster HRB 16092, Geschäftsführer: Jannik Weichert</p>
        <br />
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz (UStG): DE306515728</p>
        <br />
        <p>D-U-N-S 313725661</p>
        <br />
        <p>Inhaltlich Verantwortlicher gemäß § 55 Rundfunkstaatsvertrag (RStV): Jannik Weichert, Hafenweg 16, 48155 Münster</p>
      </div>
    </div>
  </Layout>
);
